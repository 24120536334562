import axios from "axios";
import appConstants from "../utils/appConstants";
var moment = require("moment");
export default {
  data() {
    return {
      store: this.$store?.getters?.org,
      primaryColor:
        this.$store?.getters?.org?.setting != undefined &&
          this.$store?.getters?.org?.setting?.theme?.primaryColor
          ? this.$store?.getters?.org?.setting?.theme?.primaryColor
          : "#1E1E1E",
      secondaryColor:
        this.$store?.getters.org?.setting != undefined &&
          this.$store?.getters.org?.setting?.theme?.secondaryColor
          ? this.$store.state.orgDetails?.setting?.theme?.secondaryColor
          : "white",
      font_family: this.$store?.getters?.org?.setting != undefined && this.$store?.getters?.org?.setting?.theme?.font_family ? this.$store?.getters?.org?.setting?.theme?.font_family : '',
      msg: "",
      msgType: "",
      notEmpty: [(v) => !!v || "Required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      numRules: [
        (v) => /^\d{10}$/.test(v) || "Invalid number",
      ],
      passwordRule: [
        (v) => (v && v.length > 7) || "Password should be min 8 characters.",
      ],
      audioTrack :{ soundurl : require("@/assets/notification-bell.mp3")},
      nameRules: [(v) => !!v || "Name is required"],
      slugRules: [(v) => !!v || "Slug is required"],
      fieldRequired:[(v)=>!!v||'Field is required'],
      adminHeaders:[{
        text: "Name",
        value: "firstName",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Contact No.",
        value: "phoneNo",
      },
      {
        text: "Action",
        value: "action",
        align: "center",
      },
    ],
      appDrawerItems: [{
          icon: "mdi-ballot",
          text: "Kanban",
          path: "/kanban",
          permission: "viewKanban",
        },
        {
          icon: "mdi-vector-link",
          text: "Operations",
          path: "/operations",
          permission: "listOperations",
        },
        {
          icon: "mdi-layers-triple-outline",
          text: "Tasks",
          path: "/tasks",
          permission: "listTasks",
        },
        {
          icon: "mdi-all-inclusive",
          text: "Processes",
          path: "/processes",
          permission: "listProcesses",
        },
        {
          icon: "mdi-account-tie",
          text: "Employees",
          path: "/employees",
          permission: "listEmployees",
        },
        {
          icon: "mdi-account-box",
          text: "Customers",
          path: "/customers",
          permission: "listCustomers",
        },
        {
          icon: "mdi-account-group",
          text: "Groups",
          path: "/groups",
          permission: "listGroups",
        },
        {
          icon: "mdi-image-filter-none",
          text: "Templates",
          path: "/templates",
          permission: "listTemplates",
        },
        {
          icon: "mdi-focus-field",
          text: "CustomFields",
          path: "/customFields",
          permission: "listCustomFields",
        },
        {
          icon: "mdi-bookmark",
          text: "Labels",
          path: "/labels",
          permission: "listLabels",
        },
        {
          icon: "mdi-garage-variant",
          text: "Organizations",
          path: "/organizations",
          permission: "listOrgs",
        },
        {
          icon: "mdi-newspaper-variant",
          text: "Workspaces",
          path: "/workspaces",
          permission: "listWorkspaces",
        },
        {
          icon: "mdi-notebook",
          text: "Projects",
          path: "/projects",
          permission: "listProjects",
        },
        {
          icon: "mdi-google-classroom",
          text: "Roles",
          path: "/roles",
          permission: "listRoles",
        },
        {
          icon: "mdi-slack",
          text: "Services",
          path: "/services",
          permission: "listServices",
        },
        {
          icon: "mdi-file-document",
          text: "Documents",
          path: "/documents",
          permission: "listOperations",
        },
        {
          icon: "mdi-calendar-range",
          text: "Plans",
          path: "/plans",
          permission: "listPlans",
        },
        { icon: "mdi-credit-card-outline", 
          text: "Billing Accounts",   
          path: "/billings",
          permission:"listBillingAccounts" 
        },
        {
          icon: "mdi-cog",
          text: "Settings",
          path: "/settings",
          permission: "updateOrgSetting",
        },
      ],
      requiredModules : ['Kanban', 'Operations', 'Tasks', 'Processes', 'Employees', 'Customers', 'Groups', 'Settings'],
      unrequiredModules : ['Organizations', 'Billing Accounts'],
      operationFields: [{
                defaultField: 'Customer',
                required: true
            }, {
                defaultField: 'Process',
                required: true
            }, {
                defaultField: 'Operation Title',
                required: true
            }, {
                defaultField: 'Description',
                required: false,
                dataType:'wysiwyg'
            }, {
                defaultField: 'Operation Type',
                required: true
            }, {
                defaultField: 'Owner',
                required: true,
                model: 'owner',
                items: 'employees',
                label: "Owner",
                item_text: 'displayName',
                item_value: '_id',
                dataType: 'autoComplete',
                disabled: false
            }, {
                defaultField: 'Executor',
                required: false,
                model: 'executor',
                items: 'employees',
                label: "Executor",
                item_text: 'displayName',
                item_value: '_id',
                dataType: 'autoComplete',
                disabled: false
            }, {
                defaultField: 'Start Date',
                required: true
            }, {
                defaultField: 'End Date',
                required: true
            }, {
                defaultField: 'Project',
                required: false,
                model: 'project',
                items: 'projects',
                label: "Project",
                item_text: 'displayName',
                item_value: '_id',
                dataType: 'autoComplete',
            }, {
                defaultField: 'Upload Files',
                required: false,
                dataType: 'fileUpload'
            }]
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date) {
        return moment(date).format(appConstants.DATEFORMAT.DMY);
      } else {
        return "";
      }
    },
  },
  computed: {
    logoImage() {
      return this.$store?.getters?.org?.setting != undefined && this.$store?.getters?.org?.setting?.logo?.url ? process.env.VUE_APP_API_URL + this.$store?.getters?.org?.setting?.logo?.url : require("@/assets/default-logo.png");
    },
    themeSidebar() {
      return this.$store?.getters?.org?.setting != undefined && this.$store?.getters?.org?.setting?.theme?.primaryColor ? this.$store?.getters?.org?.setting?.theme?.primaryColor : "#1E1E1E";
    },
    themeSidebarFont() {
      return {
        // 'font-family': `${this.$store?.getters?.org?.setting?.theme.font_family ? this.$store?.getters?.org?.setting?.theme.font_family : ''} !important`,
        'color': `${this.$store?.getters?.org?.setting != undefined && this.$store?.getters?.org?.setting?.theme?.secondaryColor ? this.$store?.getters?.org?.setting?.theme?.secondaryColor : "white"} !important`,
      };
    },
    themeFont() {
      var Color = this.$store?.getters?.org?.setting != undefined && this.$store?.getters?.org?.setting?.theme?.secondaryColor ? this.$store?.getters?.org?.setting?.theme?.secondaryColor : "white"
      if (Color == "white")
        return {
          // 'font-family': `${this.$store?.getters?.org?.setting?.theme.font_family ? this.$store?.getters?.org?.setting?.theme.font_family : ''} !important`,
          'color': 'black !important',
        }
      else
        return {
          // 'font-family': `${this.$store?.getters?.org?.setting?.theme.font_family ? this.$store?.getters?.org?.setting?.theme.font_family : ''} !important`,
          'color': `${this.$store?.getters?.org?.setting != undefined && this.$store?.getters?.org?.setting?.theme?.secondaryColor ? this.$store?.getters?.org?.setting?.theme?.secondaryColor : "white"} !important`,
        };
    },
    isProd(){
      return process.env.NODE_ENV == 'production'
    }

  },
  methods: {
    commonHeader() {
      return {
        headers: {
          token: this.getToken(),
          orgid: this.getOrgId(),
        },
      };
    },
    isAllowed(permission) {
      let permissions = this.getProfile().permissions;
      return permissions ? permissions.indexOf(permission) > -1 : false;
    },
    async getModules(){
      let activeBillingAccount
      let billingModules=await this.getItem(appConstants.ORGANIZATIONS_API+"/billingModules/"+this.$store.getters.org._id)
      if(billingModules.length>0)
          activeBillingAccount=(await this.getItem(appConstants.BILLING_ACCOUNTS_API + "/org/" + this.$store.getters.org._id))[0].status=='SUBSCRIBED'
      else if((billingModules.length==0||!activeBillingAccount)&&this.$store.getters.profile.level<3)
          billingModules=['Settings']
      localStorage.setItem('activeModules',billingModules)
      return billingModules
    },
    checkModule(module){
      return localStorage.getItem('activeModules').split(',').find(rec => rec == module)
    },
    goBack() {
      this.$router.go(-1);
    },
    performAction(id, icon) {
      if (icon.name == "mdi-lock-reset") {
        this.$router.push({
          name: "resetPassword",
          params: { entity: "force", id: id },
        });
      } else this.$router.push(icon.path + "" + id);
    },
    async postItem(url, data) {
      var item = {};
      data.orgId = this.getOrgId();
      await axios
        .post(this.buildApiUrl(url), data, this.commonHeader())
        .then((response) => {
          item = response.data;
        })
        .catch((error) => {
          if (
            error.response.status == appConstants.STATUS_CODE.SESSION_TIMEOUT
          ) {
            alert("Session time out , please login again");
            this.logout();
          } else throw error;
        });
      return item;
    },
    async deleteInstanceItem(url) {
      // let flag = confirm(msg)
      if (url) {
        await axios.delete(this.buildApiUrl(url), this.commonHeader()).catch(error => {
          if (error.response.status == appConstants.STATUS_CODE.SESSION_TIMEOUT) {
            alert("Session time out , please login again")
            this.logout()
          }
          else
            throw error
        })
      }
      // return flag
    },
    async getItem(url) {
      var item = [];
      await axios
        .get(this.buildApiUrl(url), this.commonHeader())
        .then((response) => {
          item = response.data;
        })
        .catch((error) => {
          if (
            error.response.status == appConstants.STATUS_CODE.SESSION_TIMEOUT
          ) {
            alert("Session time out , please login again");
            this.logout();
          } else throw error;
        });
      return item;
    },
    async putItem(url, data) {
      try {
        var item = await axios.put(
          this.buildApiUrl(url),
          data,
          this.commonHeader()
        );
        // console.log("REturning " + JSON.stringify(item));
        return item;
      } catch (error) {
        if (error.response.status == appConstants.STATUS_CODE.SESSION_TIMEOUT) {
          alert("Session time out , please login again");
          this.logout();
        } else {
          console.error(error);
          throw error;
        }
      }
    },
    async patchItem(url, data) {
      var item = {};
      await axios
        .patch(this.buildApiUrl(url), data, this.commonHeader())
        .then((response) => {
          item = response.data;
        })
        .catch((error) => {
          if (
            error.response.status == appConstants.STATUS_CODE.SESSION_TIMEOUT
          ) {
            alert("Session time out , please login again");
            this.logout();
          } else throw error;
        });
      return item;
    },
    async deleteDocument(url) {
      await axios
        .delete(this.buildApiUrl(url), this.commonHeader())
        .catch((error) => {
          if (
            error.response.status == appConstants.STATUS_CODE.SESSION_TIMEOUT
          ) {
            alert("Session time out , please login again");
            this.logout();
          } else throw error;
        });
    },
    async deleteItem(msg, url) {
      let flag = confirm(msg);
      if (flag) {
        await axios
          .delete(this.buildApiUrl(url), this.commonHeader())
          .catch((error) => {
            if (
              error.response.status == appConstants.STATUS_CODE.SESSION_TIMEOUT
            ) {
              alert("Session time out , please login again");
              this.logout();
            } else throw error;
          });
      }
      return flag;
    },
    handleError(error) {
      if (error.response.data.details) {
        this.msg = error.response.data.details;
      } else {
        this.msg = error.response.data.Message;
      }
      this.msgType = "error";
    },
    getToken() {
      return localStorage.getItem("token");
    },
    getOrgId() {
      return JSON.parse(localStorage.getItem("orgDetails"))?._id;
    },
    getUiFeatures(){
      return JSON.parse(localStorage.getItem("orgDetails"))?.uiFeatures;
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      localStorage.removeItem("activeModules")
      localStorage.removeItem("filterHistory")
      this.$store.state.token = "";
      this.$store.state.profile = "";
      this.$store.state.orgDetails = "";
      this.$store.state.filterHistory = [];
      this.$router.push("/login");
    },
    getProfile() {
      let profile = localStorage.getItem("profile");
      return profile ? JSON.parse(profile) : "";
    },
    createReference(id, displayName, value) {
      return { _id: id, displayName: displayName, value: value };
    },
    createProcessReference(process) {
      return { _id: process._id, displayName: process.name, value: process.version, workspace: process.workspace };
    },
    downloadFile(response, fileName) {
      let blob = new Blob([JSON.stringify(response.data)], {
        type: "application/json",
      });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    convertToFormData(data, files, fileLabel) {
      if (!fileLabel) throw new Error("Please provide fileLabel");
      let formData = new FormData();
      formData.append("entity", JSON.stringify(data));
      files.forEach((file) => {
        formData.append(fileLabel, file);
      });
      return formData;
    },
    buildApiUrl(url) {
      return process.env.VUE_APP_API_URL + url;
    },
    toMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);
    
      return date.toLocaleString('en-US', {
        month: 'long',
      });
    },
    async readPermissons(roleLevel) {
      let allPermissions = await this.getItem(appConstants.ROLES_API + "/permissions");
      let profilePermissions = JSON.parse(localStorage.getItem("profile")).permissions;
      let isSysAdmin = (roleLevel == 1)
      let map = {}
      allPermissions.forEach(rec => {
          if (isSysAdmin) {
              if (!map.hasOwnProperty(rec.module)) {
                  map[rec.module] = []
              }
              map[rec.module].push(rec)
          } else if (profilePermissions.indexOf(rec.code) > -1) {
              if (!map.hasOwnProperty(rec.module)) {
                  map[rec.module] = []
              }
              map[rec.module].push(rec)
          }
      })
      return {mappedPermission:map,allPermission:allPermissions}
  },
  playSound(){
    var audio = new Audio(this.audioTrack.soundurl);
    audio.play();
  },
  modulePermission(module){
    if(this.$store?.getters.profile.level==1)
      return true
    else
      return this.$store?.getters.profile.currentModules.includes(module)
  },
  taskCounter(){
    if(this.$store?.getters.taskCount && this.$store?.getters.taskCount!=undefined)
        return this.$store?.getters.taskCount
    else
        return 0
  },
  computeDateRange(start, end, span) {
    return {
        startDate: moment().add(start, span).startOf(span).format(appConstants.DATEFORMAT.YMD),
        startDateFormatted: moment().add(start, span).startOf(span).format(appConstants.DATEFORMAT.DMY),
        endDate: moment().add(end, span).endOf(span).format(appConstants.DATEFORMAT.YMD),
        endDateFormatted: moment().add(end, span).endOf(span).format(appConstants.DATEFORMAT.DMY),
    }
  },
  addAddress(orgAddress,address) {
    let addExist = orgAddress.find((add) => add.addressType == address.addressType);
    if (!addExist)
      orgAddress.push(address)
    else
      alert(`AddressType '${address.addressType}' is already added`)
    return orgAddress
  },
  
    deleteFromCart(plan) {
      let plans = this.$store?.getters.planCart
      plans.splice(plans.indexOf(plan), 1)
      this.$store.dispatch("actionUpdatePlanCart", plans);
    },
    removeFilterPageData(entity){
      let pageData=this.$store.getters.pageData
      pageData.splice(pageData.findIndex(rec=>rec.entity==entity),1)
      this.$store.dispatch("actionUpdatePageData",pageData);
    },
    formatDateToYMD(date){
      return moment(date,appConstants.DATEFORMAT.DMY).format(appConstants.DATEFORMAT.YMD)
    },
    async getEmployees() {
      let employeeList=[]
      let employees = (await this.getItem(appConstants.EMPLOYEES_API + "?fields=_id,email,firstName,lastName&size=-1")).data;
      employees.forEach((element) => {
          let employee = this.createReference(element._id,element.firstName + " " + element.lastName,element.email);
          employeeList.push(employee);
      });
      return employeeList
    },
    async assignEmployeeToTask(task, emp) {
      try {
          await this.putItem(appConstants.TASKS_API + "/assignTask/" + task._id, emp)
      } catch (error) {
          console.log(error);
      }
    },
    createFilterPayload(filterData) {
      let fieldFilter = []
      for (let i = 0; i < Object.entries(filterData).length; i++) {
        let filedObject = {}
        if (Object.values(filterData)[i].length > 0) {
          filedObject[Object.keys(filterData)[i]] = {
            '$in': Object.values(filterData)[i]
          }
        }
        fieldFilter.push(filedObject)
      }
      return { $and: fieldFilter }
    },
    checkCustomFieldModule(){
      return (this.checkModule('CustomFields') != null || this.getProfile().level < 2)
    }
  },
};
