<template>
    <v-row align="center">
        <v-col cols="12" sm="6" class="text-center">
            <label class="text-h3 font-weight-bold">Sign Up</label><br><br>
            <img src="@/assets/signup-page-image.png" height="500"><br>
            <v-row>
                <v-col cols="12" sm="4"><a href="https://easyprocess.in/tnc" target="_blank">Term and
                        Conditions</a></v-col>
                <v-col cols="12" sm="4"><a href="https://easyprocess.in/cancellation-policy" target="_blank">Refund and
                        Cancellation
                        Policy</a></v-col>
                <v-col cols="12" sm="4"><a href="https://easyprocess.in/privacy-policy" target="_blank">Privacy
                        Policy</a></v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="6">
            <v-container>
                <v-alert class="center" v-if="result" dense text type="success">
                    Registration <label v-if="result == 'fail'">failed</label><label v-else>Successfull</label>
                    <br>Login details have been sent to the registered email
                    <br> Access your Organization on <a :href="url + '/login'">{{ this.url + '/login' }}</a>
                </v-alert>
                <v-stepper class="mt-6" v-model="registrationStep" flat v-else>
                    <v-stepper-header flat>
                        <v-stepper-step :complete="registrationStep > 1" editable step="1" color="var(--base)">
                            Organization Details
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2" color="var(--base)" flat editable>
                            Plan
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-spacer></v-spacer>
                    <v-stepper-items flat>
                        <v-stepper-content class="pa-0" step="1">
                            <organization-registration-form
                                @success="registrationSuccess($event)"></organization-registration-form>
                        </v-stepper-content>
                        <v-stepper-content class="pa-0 ma-0" step="2">
                            <stripe-plans @click="planSelected($event)"></stripe-plans>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import FormButtons from "@/components/FormButtons.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import Subscription from "@/components/Subscription.vue"
import ToolTip from '@/components/ToolTipComponent'
import OrganizationRegistrationForm from '../components/OrganizationRegistrationForm.vue';
import StripePlans from '../components/StripePlans.vue';

export default {
    components: {
        FormButtons,
        AlertMessage,
        Subscription,
        ToolTip,
        OrganizationRegistrationForm,
        StripePlans
    },
    data() {
        return {
            registrationStep: 1,
            alert: false,
            organization: undefined,
            slug: "",
            trialPlans: [],
            result: ""
        }
    },
    async mounted() {
        this.result = this.$route?.query?.result
        this.slug = this.$route?.query?.slug
    },
    computed: {
        url() {
            return location.protocol + "//" + location.host + "/" + this.slug;
        }
    },
    methods: {
        registrationSuccess(org) {
            //this.$refs.subscription.initComponent()
            this.registrationStep = 2
            this.organization = org
            this.slug = this.organization.slug
        },
        async activateTrial() {
            await this.$refs.subscription.activateTrialPlan()
            this.alert = true
        },
        async planSelected(plan) {
            let response = (await this.postItem(appConstants.STRIPE_CHECKOUT, this.buildPayload(plan)))
            window.location.href = response.url
        },
        buildPayload(plan) {
            return {
                plan: {
                    _id: plan.planId,
                    type: plan.planType,
                    enableQty: plan.enableQty
                },
                customer: {
                    _id: this.organization?._id,
                    name: this.organization?.orgName,
                    email: this.organization?.orgEmail,
                    phone: this.organization?.contactNumber,
                    slug: this.organization?.slug
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.points {
    padding: 2% 15% !important;
    text-align: left;
}

.center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

li {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.5rem;
}

ul {
    list-style: none;
}

ul li:before {
    content: '✓';
    font-size: 2rem;
    color: greenyellow;
    padding-right: 10px;
    ;
}
</style>
