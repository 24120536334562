<template>
<div>
    <v-flex xs12 sm4>
        <template>
            <v-row justify="center">
                <dialog-box :dialog="dialog" title="Add Step" :errorMessage="msg" @close="close()">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card ref="form" flat>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="currentStep.name" label="Name *" :rules="notEmpty" required clearable></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <label>Description</label>
                                        <wysiwyg v-model="currentStep.description"></wysiwyg>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-checkbox v-model="currentStep.startStep" class="spacing" :disabled="currentStep.endStep" label="Start step"></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox v-model="currentStep.endStep" class="spacing" :disabled="currentStep.startStep" label="End step"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-flex v-if="!currentStep.endStep">
                                    <v-row>
                                        <v-col>
                                            <v-autocomplete v-model="currentStep.assignedTo" :items="employeeList" :return-object="true" item-text="displayName" item-value="id" label="Assign Employee" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col>
                                            <v-autocomplete v-model="currentStep.assignedTo" :items="groupList" :return-object="true" item-text="displayName" item-value="id" label="Assign Group" clearable></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-select v-model="currentStep.type" :items="['TASK', 'MEETING']" label="Type" required clearable></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field v-model.number="currentStep.sequence" label="Sequence*" hint="Sequence number" type="number" :value="currentStep.sequence || getSequenceNo()" persistent-hint :rules="notEmpty" clearable></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select v-model="currentStep.priority" :items="['HIGH', 'MEDIUM', 'LOW']" label="Priority" :rules="notEmpty" clearable></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>Turn around time</v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model.number="currentStep.turnAroundTime.number" label="Number" type="number" required clearable></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-select v-model="currentStep.turnAroundTime.type" :items="['MIN', 'DAYS']" label="Min or days" required clearable></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="showCustomField">
                                        <v-col>
                                            <v-autocomplete v-model="selectedFields" @change="updateFields()" :items="customFields" label="Add Fields" item-text="label" multiple return-object />
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="currentStep.customFields?.length>0">
                                        <v-col>
                                            <v-simple-table>
                                                <thead>
                                                    <tr>
                                                        <th>Field Name</th>
                                                        <th>Required</th>
                                                        <th>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="currentStep.customFields.length>0">
                                                    <tr v-for="(item, index) in currentStep.customFields" :key="index">
                                                        <td>{{ item.field.label }}</td>
                                                        <td>
                                                            <v-checkbox v-model="item.required"></v-checkbox>
                                                        </td>
                                                        <td>
                                                            <v-icon @click="removeField(index)">mdi-close</v-icon>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="!valid" @click="validate()">Submit</v-btn>
                                    <v-btn @click="close()"> Cancel </v-btn>
                                </v-card-actions>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </dialog-box>
            </v-row>
        </template>
    </v-flex>
    <br />
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import DialogBox from "./DialogBox.vue";
import AlertMessage from "./AlertMessage.vue";
import {
    ObjectId
} from "bson";

export default {
    components: {
        DialogBox,
        AlertMessage
    },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        dialog: {
            type: Boolean,
            default: () => false,
        },
        i: {
            type: Number,
            default: () => 0,
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        currentStep: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
    data() {
        return {
            notEmpty: [(v) => !!v || "Cannot be Empty"],
            employeeList: [],
            valid: true,
            groupList: [],
            customFields:[],
            selectedFields:[],
            showCustomField:false
        };
    },
    mounted() {
        this.msg = "";
        this.getUsers();
        this.getGroups();
        this.getCustomFields()
        this.showCustomField=this.checkCustomFieldModule()
    },
    methods: {
        getSequenceNo() {
            this.currentStep.sequence = this.i;
        },
        async getCustomFields(){
            this.customFields=(await this.getItem(appConstants.CUSTOM_FIELD_API)).data
        },
        async getUsers() {
            let employees = (
                await this.getItem(
                    appConstants.EMPLOYEES_API + "?fields=_id,email,firstName,lastName&size=-1"
                )
            ).data;
            employees.forEach((element) => {
                let employee = this.createReference(
                    element._id,
                    element.firstName + " " + element.lastName,
                    element.email
                );
                this.employeeList.push(employee);
            });
        },
        async getGroups() {
            let groups = (
                await this.getItem(appConstants.GROUPS_API + "?fields=_id,name")
            ).data;
            groups.forEach((element) => {
                let group = this.createReference(element._id, element.name, null);
                group["isGroup"] = true;
                this.groupList.push(group);
            });
        },
        close() {
            !this.msg && this.currentStep._id ?
                this.$emit("close", -1) :
                this.$emit("close", this.i - 1);
        },
        async validate() {
            if (this.$refs.form.validate()) {
                let repeatedSteps = this.steps.filter(
                    (step) => step.name == this.currentStep.name
                );
                if (repeatedSteps.length > 1)
                    this.msg =
                    "Step name " + this.currentStep.name + " is already in use";
                else {
                    if (!this.currentStep._id) this.currentStep._id = new ObjectId();
                    this.selectedFields=[]
                    this.$emit("submit");
                }
            }
        },
        updateFields(){
            this.currentStep['customFields']=[]
            this.selectedFields.forEach(rec=>{
                this.currentStep.customFields.push({field:rec,required:false})
            })
        },
        removeField(index){
            if(this.selectedFields.length>0)
                this.selectedFields.splice(index,1)
            this.currentStep.customFields.splice(index,1)
        }
    },
};
</script>

<style scoped>
.spacing {
    margin-right: 8px;
}
</style>
